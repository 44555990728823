import { createArchieStoreCreator, createHook } from '@awning/archie';
import type { TUserListing } from '@/src/typeUserListing';

export type TUserListingStore = TUserListing;
export const userListingStoreCreator = createArchieStoreCreator<TUserListingStore>({
  name: 'userListingStore',
  stateInitializer: () => ({
    advisor: {} as TUserListing['advisor'],
    awningId: '' as TUserListing['awningId'],
    listing: {} as TUserListing['listing'],
    listingId: '' as TUserListing['listingId'],
  }),
});
export const useUserListingStore = createHook(userListingStoreCreator);
