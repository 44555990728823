import * as React from 'react';
import {  Field, FieldProps ,TStyleProps } from '@awning/components';
// import { Field, FieldProps } from '@awning/components/dist/Forms';
import { UseControllerProps, useController } from 'react-hook-form';
import { handleFieldErrors } from '../useValidationResolver';

// Todo: Props are not correct for all 'as' Inputs, e.g. InputPercent
type Props = UseControllerProps &
  Omit<FieldProps, 'required'> & {
    onInitialTouch?: () => void;
    placeholder?: string;
    sx?: TStyleProps['sx'];
  };

export const FieldController: React.FC<React.PropsWithChildren<Props>> = ({
  control,
  defaultValue,
  label,
  name,
  onInitialTouch = undefined,
  rules,
  shouldUnregister,
  ...rest
}) => {
  const [untouched, setUntouched] = React.useState(true);
  const {
    field,
    fieldState: { error },
    // formState,
  } = useController({
    name,
    control,
    rules,
    defaultValue,
    shouldUnregister,
  });

  return (
    <Field
      label={label}
      {...field}
      onChange={(e: any) => {
        if (untouched) {
          setUntouched(false);
          onInitialTouch && onInitialTouch();
        }
        field.onChange(e);
      }}
      {...rest}
      required={!!rules?.required}
      // @ts-expect-error
      errors={handleFieldErrors(error)}
    />
  );
};
