// please check from time to time and see if something is no longer used/imported remove it
export const GTM_ANALYTICS_CLASSES = {
  SIGNUP_VIEWED: 'signup_viewed',
  SIGNED_UP: 'signed_up',
};

export const GtmAnalytics =
  typeof window !== 'undefined' && window?.dataLayer && !window?.__AWNING_DEBUG__
    ? window?.dataLayer
    : { push: e => console.log(e) };
