import * as React from 'react';
import axios from 'axios';
import { useRouter } from 'next/router';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { string } from 'yup';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Input,
  InputPassword,
  InputPhone,
  InputPhoneMethods,
  Label,
  Text,
} from '@awning/components';
import { acceptTermsValidation, pwValidation } from '@/src/Accounts/validations';
import { GTM_ANALYTICS_CLASSES, GtmAnalytics } from './gtm-analytics';
import { FieldController } from '@/src/FieldController';
import { useUserStore } from '@/src/shared/userStore';
import { useUserListingStore } from '@/src/properties';

const req = 'This field is required.';

type Props = { onSubmitCallback: () => void };

export const Form: React.FC<React.PropsWithChildren<Props>> = React.memo(({ onSubmitCallback }) => {
  const phoneMethods = React.useRef<InputPhoneMethods>(null);
  const { query } = useRouter();
  const setUser = useUserStore(state => state.setUser);
  const advisorId = useUserListingStore(state => state?.advisor?.id);

  const {
    control,
    formState: { isDirty, isValid, errors, isSubmitting },
    handleSubmit,
    reset,
    register,
    setValue,
  } = useForm<FieldValues>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      firstName: '',
      lastName: '',
      phone: undefined, // InputPhone is an uncontrolled component
      email: '',
      password: '',
      agreeTAndC: false,
    },
  });

  const onSubmit = async (values: FieldValues) => {
    try {
      await axios.post(`${process.env.NEXT_PUBLIC_BASE_PATH}/api/signup`, {
        ...values,
        advisorId,
        phone: phoneMethods.current?.getNumber(),
        statesInterested: [],
        query,
      });

      await axios.post(`${process.env.NEXT_PUBLIC_BASE_PATH}/api/accounts/login`, {
        email: values.email,
        password: values.password,
      });

      const userData = await axios
        .get(`${process.env.NEXT_PUBLIC_BASE_PATH}/api/users`)
        .then((res: any) => {
          setUser(res.data, true);
          return res.data;
        });

      const { id, phoneNumber, firstName, lastName, email } = userData ?? {};

      GtmAnalytics.push({
        'event': GTM_ANALYTICS_CLASSES.SIGNED_UP,
        userId: id,
        name: `${firstName} ${lastName}`,
        url: window.location.href,
        email,
        phoneNumber,
      });

      onSubmitCallback();
    } catch (e: any) {
      reset();
    }
  };

  return (
    <form className={GTM_ANALYTICS_CLASSES.SIGNUP_VIEWED} onSubmit={handleSubmit(onSubmit)}>
      <Flex sx={{ flexDirection: { base: 'column', sm: 'row' } }}>
        <FieldController
          control={control}
          name="firstName"
          as={Input}
          label="First Name"
          hideLabel
          sx={{ marginRight: { base: 0, sm: 4 } }}
          rules={{ required: req }}
          placeholder="First name"
        />
        <FieldController
          control={control}
          name="lastName"
          as={Input}
          label="Last Name"
          hideLabel
          rules={{ required: req }}
          placeholder="Last name"
        />
      </Flex>
      <Box sx={{ marginBottom: 4 }}>
        <InputPhone
          {...register('phone', {
            required: req,
            validate: () => {
              if (!phoneMethods.current) return;

              const isValid = phoneMethods.current?.isValidNumber();
              if (!isValid) return 'Not a valid phone number.';
            },
          })}
          onChange={(e: React.ChangeEvent<HTMLInputElement>, iti: InputPhoneMethods) => {
            if (!phoneMethods.current) {
              phoneMethods.current = iti;
            }

            setValue('phone', e.target.value);
          }}
          sx={{ borderColor: errors.phone ? 'red.600' : 'gray.300' }}
        />
        {errors.phone && (
          <Text sx={{ color: 'red.600', mt: 2, text: 'xs' }}>* {errors.phone.message}</Text>
        )}
      </Box>
      <FieldController
        control={control}
        name="email"
        as={Input}
        label="Email"
        hideLabel
        rules={{
          required: req,
          validate: value =>
            string()
              .email('Must be a valid email.')
              .validate(value)
              .then(() => {})
              .catch(err => err.message),
        }}
        placeholder="Email"
      />
      <FieldController
        control={control}
        name="password"
        as={InputPassword}
        helperText="Must contain 8 characters, 1 capital letter and 1 number"
        label="Password"
        hideLabel
        type="password"
        rules={{
          required: req,
          validate: value =>
            pwValidation
              .validate(value)
              .then(() => {})
              .catch(err => err.message),
        }}
        placeholder="Create a password"
      />
      <Controller
        control={control}
        name="agreeTAndC"
        rules={{
          required: req,
          validate: value =>
            acceptTermsValidation
              .validate(value)
              .then(() => {})
              .catch(err => err.message),
        }}
        render={({ field }) => (
          <>
            <Label sx={{ marginTop: 4 }}>
              <Checkbox {...field} checked={!!field.value} />
              <Text sx={{ color: 'gray.500', text: 'sm' }}>
                I agree to Awning's{' '}
                <a
                  href="https://www.awning.com/terms-of-use"
                  rel="noopener noreferrer"
                  style={{ color: 'black' }}
                  target="_blank"
                >
                  Terms and Conditions
                </a>
              </Text>
            </Label>
            {errors.agreeTAndC && (
              <Text sx={{ color: 'red.600', mt: 2, text: 'xs' }}>
                * {errors.agreeTAndC.message}
              </Text>
            )}
          </>
        )}
      />
      <Button
        type="submit"
        isDisabled={!isValid || !isDirty || isSubmitting}
        sx={{ marginTop: 8, marginBottom: 4, width: '100%' }}
      >
        {isSubmitting ? <div>Submitting...</div> : <>Create an account</>}
      </Button>
    </form>
  );
});
