import * as yup from 'yup';

// If you update this field, it will populate wherever else we use PW validation
// For example, it will update the logic for `/sign-up` as well as `accounts/reset-password`
export const pwValidation: yup.StringSchema = yup
  .string()
  .ensure()
  .min(8, 'Password must be at least 8 characters.')
  .max(64, 'Password must be less than 64 characters.')
  .matches(/(?=.*[A-Z])/, {
    excludeEmptyString: true,
    message: 'At least one capital letter is required.',
  })
  .matches(/(?=.*\d)/, {
    excludeEmptyString: true,
    message: 'At least one number is required.',
  })
  .required('This field is required.');

export const passwordSchema: yup.AnyObjectSchema = yup.object({
  password: pwValidation,
  confirm: yup
    .string()
    .ensure()
    .oneOf([yup.ref('password'), ''], 'Password does not match.')
    .required('This field is required.'),
});

export const loginSchema: yup.AnyObjectSchema = yup.object({
  email: yup
    .string()
    .ensure()
    .email('Email must be a valid email.')
    .required('This field is required.'),
  password: yup.string().ensure().required('This field is required.'),
  submission: yup.mixed().notRequired(),
});

export const acceptTermsValidation: yup.BooleanSchema = yup
  .bool()
  .oneOf([true], 'Accept Terms & Conditions is required.')
  .required();
