import * as React from 'react';
import { useRouter } from 'next/router';
import { Box, Divider, Flex, SmallAwningLogo, Text } from '@awning/components';
import { ReactComponent as CloseIcon } from '@awning/components/assets/icons/x.svg';
import { Form } from './Form';
import useModal from '@/src/useModal';
import { createSingletonStore } from '@awning/archie';

type SignupModalStore = {
  isVisible: boolean;
  step: number;
  show: ({
    step,
    content,
    config,
    onSubmitCallback,
  }: {
    step: number;
    content?: SignupModalStore['content'];
    config?: SignupModalStore['config'];
    onSubmitCallback?: Function;
  }) => void;
  content?: {
    title?: string;
    description?: string;
  };
  config: {
    allowClose?: boolean;
    showLoginLink?: boolean;
    fullPage?: boolean;
  };
  onSubmitCallback?: Function;
  hide: () => void;
};

export const useSignupModal = createSingletonStore<SignupModalStore>((set, get) => ({
  isVisible: false,
  step: 1,
  config: {
    allowClose: true,
    showLoginLink: true,
    fullPage: false,
  },
  onSubmitCallback: undefined,
  show: ({
    step = 1,
    content = {},
    config = { allowClose: true, showLoginLink: true },
    onSubmitCallback = undefined,
  }) =>
    set({
      step,
      content: { ...get().content, ...content },
      config: { ...get().config, ...config },
      isVisible: true,
      onSubmitCallback,
    }),
  hide: () => set({ isVisible: false }),
}));

export const SignupModal: React.FC<
  React.PropsWithChildren<{
    isOpen?: boolean;
    config?: {
      allowClose?: boolean;
      fullPage?: boolean;
    };
    onClose?: () => void;
  }>
> = ({ isOpen = false, onClose, config }) => {
  const { push } = useRouter();
  const {
    step = 1,
    content,
    config: { allowClose, showLoginLink, fullPage },
    onSubmitCallback,
    _isVisible,
    _show,
    _hide,
  } = useSignupModal(state => ({
    step: state.step,
    content: state.content,
    onSubmitCallback: state.onSubmitCallback,
    config: { ...state.config, ...config },
    _isVisible: state.isVisible,
    _show: state.show,
    _hide: state.hide,
  }));
  const visibility = React.useRef(_isVisible || (isOpen && step !== 2));

  const { Modal, show, hide } = useModal('signup-modal', {
    defaultShow: visibility.current,
    onHide: () => {
      _hide();
      onClose?.();
    },
    escToHide: false,
  });

  const _onSubmitCallback = () => (onSubmitCallback ? onSubmitCallback() : hide());

  React.useEffect(() => {
    if (visibility.current !== _isVisible && _isVisible) {
      show();
    }
    visibility.current = _isVisible;
  }, [_isVisible]); // eslint-disable-line

  return (
    <Modal style={fullPage ? { backgroundColor: 'white' } : {}}>
      <Flex
        sx={{
          backgroundColor: 'white',
          borderRadius: fullPage ? 0 : { base: 'none', sm: 'lg' },
          flexDirection: 'column',
          overflow: 'hidden',
          overflowY: 'auto',
          'WebkitOverflowScrolling': 'touch',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          height: { base: '100%', sm: 'auto' },
          width: { base: '100%', sm: step === 1 ? '650px' : '450px' },
          padding: { base: 0, sm: 6 },
          margin: 'auto',
        }}
      >
        {(allowClose || step === 2) && (
          <Box sx={{ width: '100%' }}>
            <Box sx={{ display: { base: 'block', sm: 'none' } }}>
              <Box
                as={CloseIcon}
                height="8px"
                width="8px"
                sx={{
                  cursor: 'pointer',
                  alignSelf: 'flex-start',
                  marginLeft: 6,
                  marginY: 5,
                }}
                onClick={hide}
              />
              <Divider sx={{ color: 'gray.200' }} />
            </Box>
            <Box sx={{ display: { base: 'none', sm: 'block' } }}>
              <Box
                as={CloseIcon}
                height="12px"
                width="12px"
                sx={{
                  cursor: 'pointer',
                  alignSelf: 'flex-start',
                }}
                onClick={hide}
              />
            </Box>
          </Box>
        )}

        {step === 1 && (
          <Flex
            sx={{
              flexDirection: 'column',
              paddingX: { base: 8, sm: 28 },
              paddingY: { base: 8, sm: !allowClose ? 12 : 6 },
            }}
          >
            <Box
              as={SmallAwningLogo}
              textColor="black"
              width="36px"
              height="36px"
              sx={{ alignSelf: 'center' }}
            />
            <Text sx={{ fontWeight: 'bold', marginBottom: 4, text: { base: '2xl', sm: '3xl' } }}>
              {content?.title ? content?.title : 'Sign up'}
            </Text>
            <Text sx={{ color: 'gray.500', marginBottom: { base: 4, sm: 5 } }}>
              {content?.description
                ? content?.description
                : 'Get unlimited access to all our properties.'}
            </Text>
            <Form
              onSubmitCallback={() => {
                _onSubmitCallback?.();
                // _show({ step: 2 });
              }}
            />
            {showLoginLink && (
              <Text
                sx={{
                  display: 'block',
                  color: 'gray.500',
                  marginTop: 4,
                }}
              >
                Already have an account?{'  '}
                <Text
                  onClick={() => {
                    hide();
                    push('/accounts/login');
                  }}
                  sx={{
                    color: 'green.600',
                    fontWeight: 'bold',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                >
                  Log in
                </Text>
              </Text>
            )}
          </Flex>
        )}
      </Flex>
    </Modal>
  );
};
