import * as React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import usePortal, { Args as ReactCoolPortalArgs } from 'react-cool-portal';
import { Flex } from '@awning/components';
import { createSingletonStore, shallow } from '@awning/archie';

type ContainerId = string;
type TModalStore = {
  show: (containerId: ContainerId) => void;
  hide: (containerId: ContainerId) => void;
  modals: {
    [key: ContainerId]: {
      isShow: boolean;
    };
  };
};

export const useModalStore = createSingletonStore<TModalStore>((set, get) => ({
  modals: {
    'global': {
      isShow: false,
    },
  },
  show: (containerId = 'global') => {
    set({
      modals: {
        ...get().modals,
        [containerId]: {
          ...get().modals[containerId],
          isShow: true,
        },
      },
    });
  },
  hide: (containerId = 'global') => {
    set({
      modals: {
        ...get().modals,
        [containerId]: {
          ...get().modals[containerId],
          isShow: false,
        },
      },
    });
  },
}));

const useModal = (containerId: string, options: Omit<ReactCoolPortalArgs, 'containerId'> = {}) => {
  const PortalStore = usePortal({
    defaultShow: false,
    internalShowHide: true,
    containerId,
    ...options,
  });

  const { isShow, show, hide } = useModalStore(
    state => ({
      isShow: state.modals?.[containerId]?.isShow,
      show: () => {
        PortalStore.show();
        state.show(containerId);
      },
      hide: () => {
        PortalStore.hide();
        state.hide(containerId);
      },
    }),
    shallow
  );

  React.useEffect(() => {
    if (!isShow && options.defaultShow) show();
  }, [containerId]); // eslint-disable-line

  const Modal: React.FC<React.PropsWithChildren<{ style?: React.CSSProperties }>> = React.memo(
    ({ style = {}, children }) => {
      const isShow = useModalStore(state => state.modals?.[containerId]?.isShow);

      return (
        <PortalStore.Portal>
          <AnimatePresence>
            <motion.div
              key={`${containerId}-modal`}
              // initial={{ opacity: 0 }}
              // animate={{ opacity: 1 }}
              // exit={{ opacity: 1 }}
              style={{
                position: 'fixed',
                top: 0,
                right: 0,
                visibility: isShow ? 'visible' : 'hidden',
                overflow: 'auto',
                zIndex: 9999,
                height: '100%',
                width: '100%',
                background: 'rgb(14 15 17 / 20%)',
                backgroundClip: 'padding-box',
                transition: 'opacity 0.5s ease-in-out',
                boxShadow: 'lg',
                ...style,
              }}
              onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                e?.stopPropagation();
                hide();
              }}
            >
              <Flex
                sx={{
                  height: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                onClick={(e: any) => {
                  e?.stopPropagation();
                }}
              >
                {children}
              </Flex>
            </motion.div>
          </AnimatePresence>
        </PortalStore.Portal>
      );
    }
  );

  return { Modal, isShow, show, hide };
};

export default useModal;
